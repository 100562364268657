@font-face {
  font-family: 'Chatweb Font';
  font-style: normal;
  font-weight: 500;
  src: url('https://assets.l1l.co/chatweb/fonts/Raleway-Medium.ttf');
}

#nixi1_chatbot {
  color: black;
  font-family: 'Chatweb Font';
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2147483647 !important;
}
